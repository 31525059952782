// service.js

import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

// Base URL for the API
// const API_BASE_URL = "http://localhost:8006/api/";
const API_BASE_URL = "https://nexus-api.appworkdemo.com/api/";
export let UserDataFromToken;

let token;
let apiClient;

apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getToken = () => {
  token = JSON.parse(localStorage.getItem("token"));
  // console.log("token after reload: ", token);

  apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserDetails = () => {
  UserDataFromToken = jwtDecode(token);
  return UserDataFromToken;
};

const errorHandle = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    toast.error("Error occurred: " + error.response.data.message);
  } else {
    // Generic error message
    toast.error("An unexpected error occurred");
  }
  console.error("Error fetching data:", error);
  throw error; // Rethrow the error so it can be handled elsewhere if needed
};

// Function to handle GET requests
export const getUserList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    const dataReceived = response.data.map((item) => {
      const date = new Date(item.LastLogin);
      const formattedDate = date.toISOString().split("T")[0];

      return {
        // id: item.ID,
        userId: item.UserID,
        user: item.Name,
        email: item.Email,
        phone: item.MobileNumber,
        lastLogin: item.LastLogin ? formattedDate : "NA",
        createdBy: item.ApprovedBy,
        status: item.Status,
        userType: item.UserType,
      };
    });

    return dataReceived;
  } catch (error) {
    // Check if there's a server response and handle it accordingly
    // errorHandle(error);
    console.log(error,"error");
    
  }
};

export const EnableUser = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    toast.success("User Enabled Successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const DisableUser = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    toast.success("User Disabled Successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const CreateUser = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    toast.success("User created Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const CreateVendor = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    console.log(response)
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};
export const CreateVendorImage = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    console.log(response)
    // toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getVendorList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    // toast.success("Vendor List Retrieved Succesfully!");
    return response.data;
  } catch (error) {
    // errorHandle(error);
    console.log(error,"error");
    
  }
};
// export const pendingActionVenderList = async (endpoint) => {
//   try {
//     getToken();
//     const response = await apiClient.get(endpoint)
//     return response?.data

//   } catch (error) {
//     errorHandle(error);
//   }
// }
// export const approveRejectVender = async (endpoint) => {
//   try {
//     getToken();
//     const response = await apiClient.get(endpoint)
//     return response?.data

//   } catch (error) {
//     errorHandle(error);
//   }
// }
export const DASHBOARDCARDDATA = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint)
    return response?.data

  } catch (error) {
    errorHandle(error);
  }
}
export const USERSUMMARY = async (endpoint ,data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}
export const SALESREPORT = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint)
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

export const EARNINGSUMMARY = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

export const USERENGAGEMENT = async (endpoint,data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint,{params : data})
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

export const VENDERPENDINGACTIONLIST = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint)
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

export const VENDORSTATUS = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data)
    toast.success(response?.data?.message);
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

export const USERSREPORT = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, data)
    // toast.success(response?.data?.message);
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

//revenue dashboard api

export const BOOKINGPAYMENTLIST = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}

//booking 

export const BOOKINGBYDATE = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    // errorHandle(error);
    console.log(error)
  }
}

export const BOOKINGDETAILSLIST = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    // errorHandle(error);
    console.log(error)
  }
}

// sales api

export const PENDINGACTIONSALES = async (endpoint,data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint,{params : data})
    return response?.data
  } catch (error) {
    // errorHandle(error);
    console.log(error)
  }
}

export const DASHBOARDDATASALES = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    // errorHandle(error);
    console.log(error)
  }
}


export const FETCHSERVICEBOOKING = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}


export const FETCHRECENTACTIVITY = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data })
    return response?.data
  } catch (error) {
    errorHandle(error);
  }
}